<template>
  <div class="app-machine vx-row justify-center pr-4 pl-4">
    <!-- Add Machines -->
    <app-machine
      class="vx-col mb-base"
      v-for="machine in Machines"
      v-bind:key="machine.id"
      :machine_real_id="machine.id"
      :machine_id="machine.serial_display"
      :machine_seri_number="machine.serial"
      :last_activity_time="machine.last_activity"
      :total_print_plate="machine.total_printed_count"
      :total_print_time="machine.total_print_time"
      :machine_type="machine.machine_type"
      :owner="machine.owner"
      :weekly_print_count="machine.weekly_print_count"
      :software_version="machine.software_version"
      :is_active="machine.is_active"
    ></app-machine>
    <p v-if="haveMachine">{{ $t("Machine_Message_1") }}</p>
  </div>
</template>

<script>
import Machine from "./components/Machine.vue";
import Api from "@/api/api";
import API from "@/api/config";

export default {
  data() {
    return {
      haveMachine: false,
      Machines: [],
    };
  },
  methods: {
    handleGetMachinesRequest(status, data) {
      this.$vs.loading.close();
      if (status == 200) {
        if (data.results.length == 0) {
          this.haveMachine = true;
          return;
        }
        this.Machines = data.results;
      } else if (status == 0) {
        this.$vs.notify({
          title: this.$t("Warning"),
          text: this.$t("Internet_Connection_Message"),
          color: "danger",
        });
      }
    },
  },
  beforeMount() {
    this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
    Api.get(API.BASEURL + API.MACHINES, this.handleGetMachinesRequest);
  },
  components: {
    appMachine: Machine,
  },
};
</script>
