<template>
  <div>
    <vx-card class="machineCard">
      <div class="vx-row">
        <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-4">
          <img
            v-if="is_active"
            class="machinePhoto"
            src="../../assets/images/pages/machine-icon.png"
            alt
          />
          <img
            v-else
            class="machinePhoto"
            src="../../assets/images/pages/machine-passive-icon.png"
            alt
          />
        </div>
        <div class="vx-col sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4">
          <vs-row>
            <p v-if="machine_type" class="machineId">{{ machine_type }} -</p>
            <p class="machineId">{{ machine_id }}</p>
          </vs-row>

          <p class="machineSerial">{{ machine_seri_number }}</p>
        </div>
      </div>
      <hr />
      <div class="vx-row pl-4 pr-4 justify-between mb-3 mt-3">
        <p class="title">{{$t('Owner')}}:</p>
        <p class="value">{{ owner }}</p>
      </div>
      <div class="vx-row pl-4 pr-4 justify-between mb-3 mt-3">
        <p class="title">{{$t('Version')}}:</p>
        <p class="value">{{ software_version }}</p>
      </div>
      <div class="vx-row pl-4 pr-4 justify-between mb-3 mt-3">
        <p class="title">{{$t('Last_Activity')}}:</p>
        <p class="value">{{ getRelativeTime(last_activity_time) }}</p>
      </div>
      <div class="vx-row pl-4 pr-4 justify-between mb-3 mt-3">
        <p class="title">{{$t('Weekly_Print')}}:</p>
        <p class="value">{{ weekly_print_count }}</p>
      </div>
      <div class="vx-row pl-4 pr-4 justify-between mb-3 mt-3">
        <p class="title">{{$t('Total_Printed_Plate')}}:</p>
        <p class="value">{{ total_print_plate }}</p>
      </div>

      <hr />
      <vs-row
        class="mt-2"
        vs-align="center"
        vs-type="flex"
        vs-justify="center"
        vs-w="12"
      >
        <vs-col vs-align="center" vs-type="flex" vs-justify="center" vs-lg="12">
          <vs-button
            class="special_button ml-1 mr-1 w-full"
            text-color="#FFFFFF"
            :color="logButtonColor"
            :to="{
              name: 'print_logs',
              query: {
                machine: machine_real_id.toString()
              }
            }"
            >{{$t('Log_Details').toUpperCase()}}</vs-button
          >
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      logButtonColor: "#0091ff"
    };
  },
  methods: {
    getRelativeTime(value) {
      return moment(moment(String(value)), "YYYYMMDD").fromNow();
    }
  },

  props: [
    "machine_real_id",
    "machine_id",
    "machine_seri_number",
    "last_activity_time",
    "total_print_plate",
    "total_print_time",
    "machine_type",
    "owner",
    "weekly_print_count",
    "software_version",
    "is_active"
  ]
};
</script>

<style scoped>
.title {
  font-weight: 600;
}
.value {
  font-weight: 400;
}
.machineCard {
  min-width: 270px;
  max-width: 400px;
}
.special_button {
  font-size: 0.9em;
  font-weight: 800;
  padding: 10px 5px 10px 5px !important;
}
.machineId {
  color: #0e0808;
  font-weight: 500;
  font-size: 18px;
}
.machineSerial {
  color: #6d7278;
}
.buttondiv {
  justify-content: center;
}
.machinePhoto {
  height: 100%;
  width: 100%;
}
</style>
